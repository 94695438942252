<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex>
        <view-title />
      </v-flex>
      <v-spacer />
      <v-flex shrink>
        <v-btn type="submit" flat @click="sectorDialog.state = true">
          <v-icon left dark>mdi-settings</v-icon>Configuration
        </v-btn>
        <div>
          <v-btn type="submit" color="primary" @click="newBrand">
            <v-icon left dark>mdi-plus</v-icon>Global Menu
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6>
        <v-text-field
          solo
          label="Search all Brands"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-data-table :headers="headers" :items="companies" :search="search">
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.brandCount || 0 }}</td>
              <td>
                <span>
                  <v-btn flat color="grey" icon @click="editBrand(props.item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <brand-dialog
      :dialog.sync="brandDialog.state"
      :isNew="brandDialog.isNew"
      :company="brandDialog.company"
      :country="brandDialog.country"
      :jdeCategories="jdeCategories"
    />
    <sector-dialog
      :dialog.sync="sectorDialog.state"
      :sector="{ id, name, country }"
      @updated="init"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { DEFAULT_JDECONFIG_ID } from '@/constants';
import brandDialog from './brandDialog';
import sectorDialog from './sectorDialog';

export default {
  components: {
    brandDialog,
    sectorDialog,
  },
  data: () => ({
    name: '',
    id: '',
    country: '',
    search: '',
    brandDialog: {
      state: false,
      isNew: true,
      company: null,
    },
    sectorDialog: {
      state: false,
      sector: {},
    },
    countryListing: [
      { text: 'Canada', value: 'CA' },
      { text: 'United States', value: 'US' },
    ],
    headers: [
      {
        text: 'Brand Global Menu',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Site Count',
        align: 'left',
        value: 'brandCount',
      },
      {
        text: '',
        align: 'left',
        value: 'companies',
      },
    ],
    jdeCategories: [],
  }),
  computed: {
    ...mapGetters('sectors', ['getSector']),
    countryMapping() {
      const map = {};
      this.countryListing.forEach((country) => {
        map[country.value] = country.text;
      });
      return map;
    },
    sector() {
      const { sector_id } = this.$route.params;
      return this.getSector(sector_id);
    },
    companies() {
      const { companies } = this.sector;
      if (!companies) return [];
      // determine number of brands
      return companies.map((company) => {
        company.brandCount =
          company.locations &&
          company.locations.reduce((result, location) => result + location.brands.length, 0);
        return company;
      });
    },
  },
  methods: {
    async getSectorInit() {
      const sector = await this.$store.dispatch('sectors/fetchSector', {
        sector_id: this.$route.params.sector_id,
        nocache: true,
      });
      const [name, country] = sector.name.split('/');
      this.name = name;
      this.id = sector.id;
      this.country = country;
      try {
        const { data } = await this.api.get(`/config/${DEFAULT_JDECONFIG_ID.ID}`);
        this.jdeCategories = [...data.jde_categories];
      } catch (err) {
        this.$toast.error('Could not load JDE Categories from Config.');
        console.error('Error With JDE Categories', err);
      }
    },
    newBrand() {
      this.brandDialog.state = true;
      this.brandDialog.isNew = true;
      this.brandDialog.updateName = '';
      this.brandDialog.company = null;
      this.brandDialog.country = this.country;
    },
    editBrand(company) {
      this.brandDialog.state = true;
      this.brandDialog.isNew = false;
      this.brandDialog.company = company;
      this.brandDialog.country = this.country;
    },
    editSector() {},

    async init() {
      this.$store.commit('adminPanel/setLoading', true);
      await this.getSectorInit();
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: `${this.name} (${this.countryMapping[this.country]})`,
        to: { name: 'sector_management', params: this.$route.params },
      });
      this.$store.commit('adminPanel/setLoading', false);
    },
  },
  async mounted() {
    try {
      await this.init();
    } catch (err) {
      this.$toast.error('could not init');
      console.error('could not init', err);
    }
  },
};
</script>

<style></style>
