import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { width: "500" },
      model: {
        value: _vm.dialogProxy,
        callback: function($$v) {
          _vm.dialogProxy = $$v
        },
        expression: "dialogProxy"
      }
    },
    [
      _c(
        VCard,
        [
          _c(
            VCardTitle,
            {
              staticClass: "H4-Secondary-Center dialog-header-text",
              attrs: { "primary-title": "" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.isNew
                    ? "Enter Global Menu Brand Name"
                    : "Edit Global Menu Brand Name"
                )
              )
            ]
          ),
          _c(
            VCardText,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    VLayout,
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Brand Name",
                              rules: _vm.nameRules
                            },
                            model: {
                              value: _vm.companyForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.companyForm, "name", $$v)
                              },
                              expression: "companyForm.name"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-self": "center"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Allow Images in Global Menu\n              "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center"
                                  }
                                },
                                [
                                  _c("toggle", {
                                    staticClass: "ml-0",
                                    staticStyle: { margin: "0 !important" },
                                    attrs: { position: "start" },
                                    model: {
                                      value: _vm.globalImagesEnabledProxy,
                                      callback: function($$v) {
                                        _vm.globalImagesEnabledProxy = $$v
                                      },
                                      expression: "globalImagesEnabledProxy"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isSectorCanadian && _vm.displayJDECategory
                    ? _c(
                        VLayout,
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            VFlex,
                            {
                              staticStyle: { "margin-top": "4vh" },
                              attrs: { xs12: "" }
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "H4-Secondary-Center dialog-header-text",
                                    attrs: { "primary-title": "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Default JDE Category\n              "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "2vh" } },
                                [
                                  _c(VSelect, {
                                    staticClass: "border-label-input",
                                    attrs: {
                                      items: _vm.jdeCategories,
                                      "item-text": "name",
                                      "item-value": "id",
                                      outline: ""
                                    },
                                    model: {
                                      value: _vm.jdeCategoryId,
                                      callback: function($$v) {
                                        _vm.jdeCategoryId = $$v
                                      },
                                      expression: "jdeCategoryId"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(VDivider),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { color: "primary", flat: "" },
                  on: { click: _vm.clear }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary",
                    flat: "",
                    disabled: _vm.isSaveDisabled
                  },
                  on: { click: _vm.save }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }