<template>
  <v-dialog v-model="dialogProxy" width="500">
    <v-card>
      <v-card-title class="H4-Secondary-Center dialog-header-text" primary-title>{{
        isNew ? 'Enter Global Menu Brand Name' : 'Edit Global Menu Brand Name'
      }}</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field label="Brand Name" v-model="companyForm.name" :rules="nameRules" />
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div style="display: flex; align-self: center;">
                  Allow Images in Global Menu
                </div>
                <div style="display: flex; justify-content: center;">
                  <toggle
                    v-model="globalImagesEnabledProxy"
                    :position="'start'"
                    class="ml-0"
                    style="margin: 0 !important;"
                  />
                </div>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="isSectorCanadian && displayJDECategory">
            <v-flex xs12 style="margin-top: 4vh">
              <div>
                <span class="H4-Secondary-Center dialog-header-text" primary-title>
                  Default JDE Category
                </span>
              </div>
              <div style="margin-top: 2vh;">
                <v-select
                  v-model="jdeCategoryId"
                  class="border-label-input"
                  :items="jdeCategories"
                  item-text="name"
                  item-value="id"
                  outline
                ></v-select>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="clear">Cancel</v-btn>
        <v-btn color="primary" flat @click="save" :disabled="isSaveDisabled">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import rules from '@/rules';
import getStage from '../../store/modules/adminPanel.js';

export default {
  props: ['dialog', 'isNew', 'company', 'country', 'jdeCategories'],
  data: () => ({
    companyForm: {
      name: '',
      is: {
        global_images_enabled: false,
      },
      meta: {
        jde_category: '',
      },
    },
    nameRules: [rules.required('Brand name is required')],
    isValid: true,
  }),
  computed: {
    isSectorCanadian() {
      return this.country === 'CA';
    },
    displayJDECategory() {
      return getStage.state.stage === 'v1';
    },
    dialogProxy: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit('update:dialog', v);
      },
    },
    globalImagesEnabledProxy: {
      get() {
        return (this.companyForm.is && this.companyForm.is.global_images_enabled) || false;
      },
      set(toggleValue) {
        if (!toggleValue && this.company && !this.company.is) {
          this.$delete(this.companyForm, 'is');
          return;
        }
        this.$set(this.companyForm, 'is', {
          ...this.companyForm.is,
          global_images_enabled: toggleValue,
        });
      },
    },
    jdeCategoryId: {
      get() {
        return (this.companyForm.meta && this.companyForm.meta.jde_category) || '';
      },
      set(value) {
        this.$set(this.companyForm, 'meta', {
          ...this.companyForm.meta,
          jde_category: value,
        });
      },
    },
    isSaveDisabled() {
      if (this.isNew) return !this.isValid;
      const is_form_unchanged =
        this.companyForm.name === this.company.name &&
        isEqual(this.companyForm.is, this.company.is);

      const metaChanged =
        !this.isSectorCanadian ||
        (!this.company.meta && this.companyForm.meta && !this.companyForm.meta.jde_category) ||
        isEqual(this.companyForm.meta, this.company.meta);

      return !this.isValid || (is_form_unchanged && metaChanged);
    },
  },
  watch: {
    dialog(v) {
      if (!v || this.isNew) return;
      this.companyForm.name = this.company.name;
      if (this.company.is) {
        this.companyForm.is = cloneDeep(this.company.is);
      } else {
        this.$delete(this.companyForm, 'is');
      }
      if (this.company.meta) {
        this.companyForm.meta = cloneDeep(this.company.meta);
      } else {
        this.$delete(this.companyForm, 'meta');
      }

      this.$refs.form.resetValidation();
    },
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // create new company
      const sector = this.$route.params.sector_id;
      if (this.isNew) {
        try {
          const companyInfo = {
            sector,
            name: this.companyForm.name,
            is: this.companyForm.is,
            meta: this.companyForm.meta,
          };

          await this.$store.dispatch('sectors/createCompany', companyInfo);
          this.$toast('Created Brand');
          this.clear();
        } catch (err) {
          let msg = 'Could not create Brand';
          if (err && err.response && err.response.data && err.response.data.code === 400.37) {
            msg = 'Brand already exists';
          }
          this.$toast.error(msg);
        }
      } else {
        try {
          await this.$store.dispatch('sectors/updateCompany', {
            id: this.company.id,
            sector,
            name: this.companyForm.name,
            is: this.companyForm.is,
            meta: this.companyForm.meta,
            locations: this.company.locations,
          });

          this.$toast('Updated Brand');
          this.clear();
        } catch (err) {
          let msg = 'Could not update Brand';
          if (err && err.response && err.response.data && err.response.data.code === 400.37) {
            msg = 'Brand already exists';
          }
          this.$toast.error(msg);
        }
      }
    },
    clear() {
      this.$refs.form.resetValidation();
      this.companyForm = {
        name: '',
        is: {
          global_images_enabled: false,
        },
        meta: {},
      };
      this.dialogProxy = false;
    },
  },
};
</script>

<style scoped>
.dialog-header-text {
  font-size: 2em;
}
</style>
